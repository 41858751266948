import { useEffect, useState } from "react";
import Heatmap from "./Heatmap";
import "./App.css";
import { Oval } from "react-loader-spinner";

function sortMatrixLines(matrix, ascending = true) {
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length - 1; j++) {
      for (let k = 0; k < matrix[i].length - j - 1; k++) {
        if (ascending) {
          if (matrix[i][k] > matrix[i][k + 1]) {
            let temp = matrix[i][k];
            matrix[i][k] = matrix[i][k + 1];
            matrix[i][k + 1] = temp;
          }
        } else {
          if (matrix[i][k] < matrix[i][k + 1]) {
            let temp = matrix[i][k];
            matrix[i][k] = matrix[i][k + 1];
            matrix[i][k + 1] = temp;
          }
        }
      }
    }
  }
}

function sortMatrixColumns(matrix, ascending = true) {
  for (let j = 0; j < matrix[0].length; j++) {
    for (let i = 0; i < matrix.length - 1; i++) {
      for (let k = 0; k < matrix.length - i - 1; k++) {
        if (ascending) {
          if (matrix[k][j] > matrix[k + 1][j]) {
            let temp = matrix[k][j];
            matrix[k][j] = matrix[k + 1][j];
            matrix[k + 1][j] = temp;
          }
        } else {
          if (matrix[k][j] < matrix[k + 1][j]) {
            let temp = matrix[k][j];
            matrix[k][j] = matrix[k + 1][j];
            matrix[k + 1][j] = temp;
          }
        }
      }
    }
  }
}

function sortMatrixVertical(matrix, vector, factor) {
  const count = new Array(matrix.length).fill(0);

  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (matrix[i][j] === factor) {
        count[i]++;
      }
    }
  }

  // Ordenar a matriz com base na contagem
  for (let i = 0; i < matrix.length - 1; i++) {
    for (let j = 0; j < matrix.length - i - 1; j++) {
      if (count[j] > count[j + 1]) {
        // Troca de linhas na matriz
        const tempRow = matrix[j];
        const tempItem = vector[j];
        matrix[j] = matrix[j + 1];
        matrix[j + 1] = tempRow;
        vector[j] = vector[j + 1];
        vector[j + 1] = tempItem;

        // Troca de contagem
        const tempCount = count[j];
        count[j] = count[j + 1];
        count[j + 1] = tempCount;
      }
    }
  }
}

function sortMatrixHorizontal(matrix, vector, factor) {
  const count = new Array(matrix[0].length).fill(0);

  const m = JSON.parse(JSON.stringify(matrix));
  const v = JSON.parse(JSON.stringify(vector));

  console.log("Matriz original");
  console.log(m);
  console.log("Labels originais");
  console.log(v);
  console.log("factor: :" + factor);

  // Contagem da presença do fator em cada coluna
  for (let j = 0; j < matrix[0].length; j++) {
    for (let i = 0; i < matrix.length; i++) {
      if (matrix[i][j] === factor) {
        count[j]++;
      }
    }
  }

  // Ordenar a matriz com base na contagem count
  for (let i = 0; i < matrix[0].length - 1; i++) {
    for (let j = 0; j < matrix[0].length - i - 1; j++) {
      if (count[j] > count[j + 1]) {
        for (let k = 0; k < matrix.length; k++) {
          const temp = matrix[k][j];
          matrix[k][j] = matrix[k][j + 1];
          matrix[k][j + 1] = temp;
        }

        // Troca de contagem
        const tempCount = count[j];
        count[j] = count[j + 1];
        count[j + 1] = tempCount;

        // Troca correspondente no vetor
        const tempVectorValue = vector[j];
        vector[j] = vector[j + 1];
        vector[j + 1] = tempVectorValue;
      }
    }
  }
}

function sortAndGroupedLines(dataset, vector, best, method) {
  if (best) {
    sortMatrixLines(dataset, false);
    if (method == "score") {
      sortMatrixByRowSum(dataset, vector, true);
    } else if (method == "categorico") {
      sortMatrixVertical(dataset, vector, 1);
      sortMatrixVertical(dataset, vector, 2);
      sortMatrixVertical(dataset, vector, 3);
    }
  } else {
    sortMatrixLines(dataset, true);
    if (method == "score") {
      sortMatrixByRowSum(dataset, vector, false);
    } else if (method == "categorico") {
      sortMatrixVertical(dataset, vector, 3);
      sortMatrixVertical(dataset, vector, 2);
      sortMatrixVertical(dataset, vector, 1);
    }
  }
}

function sortAndGroupedColumns(dataset, vector, best, method) {
  if (best) {
    sortMatrixColumns(dataset, false);
    if (method == "score") {
      sortMatrixByColumnSum(dataset, vector, true);
    } else if (method == "categorico") {
      sortMatrixHorizontal(dataset, vector, 1);
      sortMatrixHorizontal(dataset, vector, 2);
      sortMatrixHorizontal(dataset, vector, 3);
    }
  } else {
    sortMatrixColumns(dataset, true);
    if (method == "score") {
      sortMatrixByColumnSum(dataset, vector, false);
    } else if (method == "categorico") {
      sortMatrixHorizontal(dataset, vector, 3);
      sortMatrixHorizontal(dataset, vector, 2);
      sortMatrixHorizontal(dataset, vector, 1);
    }
  }
}

function sumArray(arr) {
  return arr.reduce((acc, curr) => acc + curr, 0);
}

function sortMatrixByRowSum(matrix, studentsArray, ascending = true) {
  for (let i = 0; i < matrix.length; i++) {
    for (let j = i + 1; j < matrix.length; j++) {
      const sumI = sumArray(matrix[i]);
      const sumJ = sumArray(matrix[j]);

      if ((ascending && sumI > sumJ) || (!ascending && sumI < sumJ)) {
        const tempRow = matrix[i];
        matrix[i] = matrix[j];
        matrix[j] = tempRow;

        const tempStudent = studentsArray[i];
        studentsArray[i] = studentsArray[j];
        studentsArray[j] = tempStudent;
      }
    }
  }
}

function sortMatrixByColumnSum(matrix, columnVector, ascending = true) {
  const numLinhas = matrix.length;
  const numColunas = matrix[0].length;

  const somaColunas = new Array(numColunas).fill(0);

  for (let i = 0; i < numLinhas; i++) {
    for (let j = 0; j < numColunas; j++) {
      somaColunas[j] += matrix[i][j];
    }
  }

  // Criar um array de índices das colunas e ordená-los com base nas somas
  const colunasOrdenadas = Array.from(
    { length: numColunas },
    (_, index) => index
  ).sort((a, b) =>
    ascending
      ? somaColunas[a] - somaColunas[b]
      : somaColunas[b] - somaColunas[a]
  );

  // Reorganizar a matriz e o columnVector com base nas colunas ordenadas
  const matrizOrdenada = [];
  const vetorOrdenado = [];

  for (let i = 0; i < numColunas; i++) {
    const colunaOriginal = colunasOrdenadas[i];

    // Reorganizar a matriz
    const colunaMatriz = [];
    for (let j = 0; j < numLinhas; j++) {
      colunaMatriz.push(matrix[j][colunaOriginal]);
    }
    matrizOrdenada.push(colunaMatriz);

    // Reorganizar o columnVector
    vetorOrdenado.push(columnVector[colunaOriginal]);
  }

  // Atualizar a matriz original com a matriz ordenada
  for (let i = 0; i < numLinhas; i++) {
    for (let j = 0; j < numColunas; j++) {
      matrix[i][j] = matrizOrdenada[j][i];
    }
  }

  // Atualizar o columnVector original com o vetor ordenado
  for (let i = 0; i < numColunas; i++) {
    columnVector[i] = vetorOrdenado[i];
  }
}

function calculateScores(matrix, orderBy) {
  let scores = [];

  if (orderBy === "rows") {
    // Calcula os scores das linhas
    scores = matrix.map((row) => row.reduce((acc, val) => acc + val, 0));
  } else if (orderBy === "columns") {
    // Calcula os scores das colunas
    const numRows = matrix.length;
    const numCols = matrix[0].length;

    for (let j = 0; j < numCols; j++) {
      let colScore = 0;
      for (let i = 0; i < numRows; i++) {
        colScore += matrix[i][j];
      }
      scores.push(colScore);
    }
  }

  return scores;
}

const App = () => {
  const [assessments, setAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedClass, setSelectedClass] = useState("");

  const [ordenar, setOrdenar] = useState("all");
  const [ordem, setOrdem] = useState("original");
  const [metodo, setMetodo] = useState("score");
  const [label, setLabel] = useState();
  const [sort, setSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentQuestions, setStudentQuestions] = useState([]);
  const [clustered, setClustered] = useState();
  const [title, setTitle] = useState("");

  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedStudent, setSelectedStudent] = useState();

  const [question, setQuestion] = useState();
  const [student, setStudent] = useState();

  const [originalStudents, setOriginalStudents] = useState([]);
  const [originalQuestions, setOriginalQuestions] = useState([]);
  const [originalStudentQuestions, setOriginalStudentQuestions] = useState([]);

  const [scoresRow, setScoresRow] = useState([]);
  const [scoresColumn, setScoresColumn] = useState([]);

  const getAccessToken = async () => {
    try {
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const clientId = process.env.REACT_APP_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

      // Make a POST request to obtain the access token
      const tokenResponse = await fetch(
        "https://api.edu.cenpe.ufc.br/oauth2/o/token/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `grant_type=password&username=${encodeURIComponent(
            username
          )}&password=${encodeURIComponent(
            password
          )}&client_id=${encodeURIComponent(
            clientId
          )}&client_secret=${encodeURIComponent(clientSecret)}`,
        }
      );

      const tokenData = await tokenResponse.json();

      if (tokenData.access_token) {
        localStorage.setItem("accessToken", tokenData.access_token);
      } else {
        throw new Error("Failed to obtain access token");
      }
    } catch (error) {
      console.error("Error obtaining access token:", error);
    }
  };

  const fetchAssessments = async (assessmentId, examId, taskId, schoolId) => {
    try {
      setLoading(true); // Definir loading como true antes da requisição

      let url =
        "https://api.edu.cenpe.ufc.br/assessments/assessments/filters/?";

      if (assessmentId) url += `assessment_id=${assessmentId}`;
      if (examId) url += `&exam_id=${examId}`;
      if (taskId != "all") if (taskId) url += `&task_id=${taskId}`;
      if (schoolId) url += `&school_id=${schoolId}`;
      url += `&anonymous=${true}`;

      const assessmentsResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const assessmentsData = await assessmentsResponse.json();
      setAssessments(assessmentsData);
      setLoading(false); // Definir loading como false após obter os dados
    } catch (error) {
      setLoading(false); // Definir loading como false em caso de erro
      console.error("Error fetching assessments:", error);
    }
  };

  const fetchCSV = async (assessmentId, examId, taskId, classId) => {
    try {
      // Set loading to true before making the request
      setLoading(true);
      let url =
        "https://api.edu.cenpe.ufc.br/assessments/export-class-heat-map-csv?";

      if (assessmentId) url += `assessment_id=${assessmentId}`;
      if (examId) url += `&exam_id=${examId}`;
      if (taskId !== "all" && taskId) url += `&task_id=${taskId}`;
      if (classId) url += `&class_id=${classId}`;

      const csvResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const csvData = await csvResponse.text();
      // Remove '\r' and convert empty cells to 1
      const sanitizedCSVData = csvData.replace(/\r/g, "").replace(/,,/g, ",1,");

      // Split CSV data by lines and remove empty lines
      const csvRows = sanitizedCSVData
        .split("\n")
        .filter((row) => row.trim() !== "");

      // Extract questions from the first row (excluding 'student' column)
      const csvQuestions = csvRows[0]
        .split(",")
        .slice(1)
        .map((value) => parseFloat(value));
      setQuestions(csvQuestions);

      // Extract students and student-question matrix from the remaining rows
      const csvStudents = [];
      const studentQuestionMatrix = [];
      for (let i = 1; i < csvRows.length; i++) {
        const columns = csvRows[i].split(",");
        const studentId = parseFloat(columns[0]);
        const studentAnswers = columns
          .slice(1)
          .map((value) => parseFloat(value.trim() === "" ? "1" : value));
        csvStudents.push(studentId);
        studentQuestionMatrix.push(studentAnswers);
      }

      setStudents(csvStudents);
      setStudentQuestions(studentQuestionMatrix);
      // Handle the CSV data as needed (e.g., download, parse, display, etc.)
      setOriginalStudents(JSON.parse(JSON.stringify(csvStudents)));
      setOriginalQuestions(JSON.parse(JSON.stringify(csvQuestions)));
      setOriginalStudentQuestions(
        JSON.parse(JSON.stringify(studentQuestionMatrix))
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching CSV:", error);
      setLoading(false);
    }
  };

  const getItem = async (id) => {
    try {
      const url = `https://api.edu.cenpe.ufc.br/items/items/${id}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.id == id) {
          setQuestion({
            id: data.id,
            title: data.title,
            content: data.content,
          });
        }
      } else {
        throw new Error("Erro ao obter os dados");
      }
    } catch (error) {
      console.error("Erro ao obter o item:", error);
    }
  };

  const getStudent = async (id) => {
    try {
      const url = `https://api.edu.cenpe.ufc.br/oauth2/users/${id}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.id == id) {
          setStudent({
            username: data.username,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
          });
        }
      } else {
        throw new Error("Erro ao obter os dados");
      }
    } catch (error) {
      console.error("Erro ao obter o item:", error);
    }
  };

  const handleAssessmentChange = (event) => {
    setSelectedAssessment(event.target.value);
    setSelectedExam("");
    setSelectedTask("");
    setSelectedSchool("");
    setSelectedClass("");
    clearData();
    fetchAssessments(event.target.value, "", "", "");
  };

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
    setSelectedTask("");
    setSelectedSchool("");
    setSelectedClass("");
    clearData();
    fetchAssessments(selectedAssessment, event.target.value, "", "");
  };

  const handleTaskChange = (event) => {
    setSelectedTask(event.target.value);
    setSelectedSchool("");
    setSelectedClass("");
    clearData();
    fetchAssessments(selectedAssessment, selectedExam, event.target.value, "");
  };

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
    setSelectedClass("");
    clearData();
    fetchAssessments(
      selectedAssessment,
      selectedExam,
      selectedTask,
      event.target.value
    );
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    fetchCSV(
      selectedAssessment,
      selectedExam,
      selectedTask,
      event.target.value
    );
  };

  const handleData = (v_students, v_questions, m_student_question) => {
    setStudents(v_students);
    setQuestions(v_questions);
    setStudentQuestions(m_student_question);
  };

  const handleClustered = (data) => {
    setClustered(data);
  };

  const handleRestore = () => {
    setStudents(originalStudents);
    setQuestions(originalQuestions);
    setStudentQuestions(originalStudentQuestions);
    setSort(false);
    handleClustered(false);
    setTitle("");
  };

  const clearData = () => {
    setStudents([]);
    setQuestions([]);
    setStudentQuestions([]);
    setSort(false);
    setOrdenar("all");
    setOrdem("original");
    handleClustered(false);
    setTitle("");
    setOriginalQuestions([]);
    setOriginalStudents([]);
    setOriginalStudentQuestions([]);
  };

  const handleDownload = (data) => {
    setDownload(true);
  };

  const handleClose = () => {
    setQuestion(null);
    setSelectedQuestion(null);
    setSelectedStudent(null);
    setStudent(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAccessToken();
        fetchAssessments();
      } catch (error) {
        console.error("Ocorreu um erro:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let dataset = JSON.parse(JSON.stringify(originalStudentQuestions));
    let data_student = JSON.parse(JSON.stringify(originalStudents));
    let data_question = JSON.parse(JSON.stringify(originalQuestions));
    let data_clustered = false;

    if (ordenar === "students" && ordem === "descendning") {
      sortAndGroupedLines(dataset, data_student, false, metodo);
      setTitle("Alunos ordenados por desempenho decrescente");
      if (metodo == "score") {
        const rowScores = calculateScores(dataset, "rows");
        setScoresColumn([]);
        setScoresRow(rowScores);
      }
    }
    if (ordenar === "students" && ordem === "ascending") {
      sortAndGroupedLines(dataset, data_student, true, metodo);
      setTitle("Alunos ordenados por desempenho crescente");
      if (metodo == "score") {
        const rowScores = calculateScores(dataset, "rows");
        setScoresColumn([]);
        setScoresRow(rowScores);
      }
    }
    if (ordenar === "questions" && ordem === "ascending") {
      sortAndGroupedColumns(dataset, data_question, false, metodo);
      setTitle("Questões ordenadas por dificuldade crescente");
      if (metodo == "score") {
        const colScores = calculateScores(dataset, "columns");
        setScoresRow([]);
        setScoresColumn(colScores);
      }
    }
    if (ordenar === "questions" && ordem === "descendning") {
      sortAndGroupedColumns(dataset, data_question, true, metodo);
      setTitle("Questões ordenadas por dificuldade decrescente");
      if (metodo == "score") {
        const colScores = calculateScores(dataset, "columns");
        setScoresRow([]);
        setScoresColumn(colScores);
      }
    }
    if (ordenar === "all" && ordem === "original") {
      setScoresColumn([]);
      setScoresRow([]);
      setTitle("");
    }
    if (ordenar === "all" && ordem === "ascending") {
      setScoresColumn([]);
      setScoresRow([]);
      sortMatrixByRowSum(dataset, data_student, true);
      sortMatrixByColumnSum(dataset, data_question, true);
      setTitle("Alunos x Questões ordenados de forma crescente");
    }
    if (ordenar === "all" && ordem === "descendning") {
      setScoresColumn([]);
      setScoresRow([]);
      sortMatrixByRowSum(dataset, data_student, false);
      sortMatrixByColumnSum(dataset, data_question, false);
      setTitle("Alunos x Questões ordenados de forma decrescente");
    }
    if (ordenar === "students") data_clustered = "x";
    if (ordenar === "questions") data_clustered = "y";

    handleClustered(data_clustered);
    handleData(data_student, data_question, dataset);
  }, [sort]);

  useEffect(() => {
    if (selectedQuestion) {
      getItem(selectedQuestion);
      setSelectedStudent(null);
      setStudent(null);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    getStudent(selectedStudent);
    setSelectedQuestion(null);
    setQuestion(null);
  }, [selectedStudent]);

  useEffect(() => {
    console.log("scoresRow");
    console.log(scoresRow);
    console.log("scoresColumn");
    console.log(scoresColumn);
  }, [scoresColumn, scoresRow]);

  return (
    <>
      <div class="navbar">
        <div class="logo">
          <img
            src="./logo.png"
            style={{ marginLeft: "23px" }}
            alt="Logo da Empresa"
          />
        </div>
      </div>
      <div className="container">
        <div className="controllers">
          <div className="csv">
            <div className="combo">
              <span className="group-title">FILTRAGENS</span>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="assessment">
                Avaliação:
              </label>
              <select
                name="assessment"
                id="assessment"
                value={selectedAssessment}
                onChange={handleAssessmentChange}
                style={selectedAssessment ? { fontWeight: "400" } : {}}
              >
                <option value="" disabled selected>
                  Selecione uma Avaliação
                </option>
                {assessments.assessments &&
                  assessments.assessments.map((assessment) => (
                    <option key={assessment.id} value={assessment.id}>
                      {assessment.title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="exam">
                Exame:
              </label>
              <select
                name="exam"
                id="exam"
                disabled={!selectedAssessment}
                value={selectedExam}
                onChange={handleExamChange}
                style={selectedExam ? { fontWeight: "400" } : {}}
              >
                <option value="" disabled selected>
                  Selecione um Exame
                </option>
                {assessments.exams &&
                  assessments.exams.map((exam) => (
                    <option key={exam.exam_id} value={exam.exam_id}>
                      {exam.exam_title}
                    </option>
                  ))}
              </select>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="task">
                Tarefa:
              </label>
              <select
                name="task"
                id="task"
                disabled={!selectedExam}
                value={selectedTask}
                onChange={handleTaskChange}
                style={selectedTask ? { fontWeight: "400" } : {}}
              >
                <option value="" disabled selected>
                  Selecione uma Tarefa
                </option>
                <option value="all">Todas as Tarefas</option>
                {assessments.tasks &&
                  assessments.tasks.map((exam) => (
                    <option key={exam.task_id} value={exam.task_id}>
                      Tarefa {exam.task_number}
                    </option>
                  ))}
              </select>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="school">
                Escola:
              </label>
              <select
                name="school"
                id="school"
                disabled={!selectedTask}
                value={selectedSchool}
                onChange={handleSchoolChange}
                style={selectedSchool ? { fontWeight: "400" } : {}}
              >
                <option value="" disabled selected>
                  Selecione uma Escola
                </option>
                {assessments.schools &&
                  assessments.schools.map((school) => (
                    <option key={school.school_id} value={school.school_id}>
                      Escola {school.school_id}
                    </option>
                  ))}
              </select>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="class">
                Turma:
              </label>
              <select
                name="class"
                id="class"
                disabled={assessments.classes ? false : true}
                value={selectedClass}
                onChange={handleClassChange}
                style={selectedClass ? { fontWeight: "400" } : {}}
              >
                <option value="" disabled selected>
                  Selecione uma Turma
                </option>
                {assessments.classes &&
                  assessments.classes.map((_class) => (
                    <option key={_class.class_id} value={_class.class_id}>
                      {_class.class_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="filters">
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="Ordenar">
                Ordenar:
              </label>
              <select
                id="Ordenar"
                disabled={studentQuestions.length > 0 ? false : true}
                onChange={(e) => {
                  setLabel(
                    e.target.value === "questions"
                      ? "Dificuldade"
                      : e.target.value === "students"
                      ? "Desempenho"
                      : ""
                  );
                  if (
                    e.target.value === "questions" ||
                    e.target.value === "students"
                  ) {
                    setOrdem("ascending");
                  } else {
                    setOrdem("original");
                  }
                  if (e.target.value == "all") {
                    setMetodo("score");
                  }
                  setOrdenar(e.target.value);
                }}
              >
                <option value="all">Alunos x Questões</option>
                <option value="questions">Questões</option>
                <option value="students">Alunos</option>
              </select>
            </div>
            <div className="combo">
              <label style={{ display: "block" }} htmlFor="Ordem">
                Ordem:
              </label>
              <select
                id="Ordem"
                disabled={studentQuestions.length > 0 ? false : true}
                onChange={(e) => setOrdem(e.target.value)}
              >
                {ordenar === "all" && (
                  <>
                    <option
                      selected={ordem == "original" ? true : false}
                      value="original"
                    >
                      {label} Original
                    </option>

                    <option
                      selected={ordem == "ascending" ? true : false}
                      value="ascending"
                    >
                      Crescente
                    </option>
                    <option
                      selected={ordem == "descendning" ? true : false}
                      value="descendning"
                    >
                      Descrescente
                    </option>
                  </>
                )}
                {ordenar === "students" && (
                  <>
                    <option
                      selected={ordem == "ascending" ? true : false}
                      value="ascending"
                    >
                      {metodo == "score"
                        ? "Desempenho Crescente"
                        : "Desempenho Crescente (Verde)"}
                    </option>
                    <option
                      selected={ordem == "descendning" ? true : false}
                      value="descendning"
                    >
                      {metodo == "score"
                        ? "Desempenho Descrescente"
                        : "Desempenho Descrescente (Vermelho)"}
                    </option>
                  </>
                )}
                {ordenar === "questions" && (
                  <>
                    <option
                      selected={ordem == "ascending" ? true : false}
                      value="ascending"
                    >
                      {metodo == "score"
                        ? "Dificuldade Crescente"
                        : "Dificuldade Crescente (Vermelho)"}
                    </option>
                    <option
                      selected={ordem == "descendning" ? true : false}
                      value="descendning"
                    >
                      {metodo == "score"
                        ? "Dificuldade Decrescente"
                        : "Dificuldade Decrescente (Verde)"}
                    </option>
                  </>
                )}
              </select>
            </div>
            <div
              className="combo"
              style={
                ordenar != "all" ? { display: "block" } : { display: "none" }
              }
            >
              <label style={{ display: "block" }} htmlFor="Metodo">
                Método:
              </label>
              <select id="Metodo" onChange={(e) => setMetodo(e.target.value)}>
                <option value="score">Score</option>
                <option value="categorico">Categórico</option>
              </select>
            </div>
            <div className="printscreen">
              <button
                id="btn-sort"
                onClick={() => {
                  setSort(!sort);
                }}
              >
                <span class="material-symbols-outlined">sort</span>
                <span>Ordenar</span>
              </button>
            </div>
            {questions.length > 0 &&
              students.length > 0 &&
              studentQuestions.length > 0 && (
                <div className="printscreen">
                  <button onClick={() => handleDownload(true)}>
                    <span class="material-symbols-outlined">photo_camera</span>
                    <span>Capturar</span>
                  </button>
                </div>
              )}
          </div>
        </div>
        <div class="heatmap">
          {loading ? (
            <>
              <Oval
                height={80}
                width={80}
                color="#58B1DD"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#47B9EE"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </>
          ) : questions.length > 0 &&
            students.length > 0 &&
            studentQuestions.length > 0 ? (
            <Heatmap
              questions={questions}
              students={students}
              studentQuestions={studentQuestions}
              clustered={clustered}
              method={metodo}
              ordem={ordem}
              ordenar={ordenar}
              assessment={
                assessments.assessments.find(
                  (assessment) => assessment.id == selectedAssessment
                ).title
              }
              exam={
                assessments.exams.find((exam) => exam.exam_id == selectedExam)
                  .exam_title
              }
              school={
                assessments.schools.find(
                  (school) => school.school_id == selectedSchool
                ).school_name
              }
              classe={
                assessments.classes.find(
                  (classe) => classe.class_id == selectedClass
                ).class_name
              }
              download={download}
              setDownload={setDownload}
              title={title}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              scoresRow={scoresRow}
              scoresColumn={scoresColumn}
            />
          ) : (
            <h5 className="no-data">Sem Avaliação para Análise</h5>
          )}
          <span className="cenpe-footer">Basedata by: CEnPE</span>
        </div>
      </div>
      {question && (
        <div className="info">
          <button className="closeButton" onClick={handleClose}>
            X
          </button>
          <div style={{ display: "flex", color: "#FFF" }}>
            <span className="material-symbols-outlined">assignment</span>
            <p style={{ marginLeft: "10px", marginTop: "3px" }}>
              {question.title}{" "}
              <span style={{ color: "#B7B7B7" }}>#{question.id}</span>
            </p>
          </div>
          <p>{question.content}</p>
        </div>
      )}
      {student && (
        <div className="info">
          <button className="closeButton" onClick={handleClose}>
            X
          </button>
          <div style={{ display: "flex", color: "#FFF" }}>
            <span class="material-symbols-outlined">person_raised_hand</span>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "start"}}>
              <p style={{ marginLeft: "10px", marginTop: "3px", marginBottom: "4px"}}>
                {student.first_name.split(" ")[0]}{"******** "}
              </p>
              <p style={{ margin: 0, marginLeft: "10px", fontSize: "12px", fontWeight: 500}}>
                MATRÍCULA: {student.username}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
